const TagId = 'google-auth';
const RequiredScopes =
  'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email';

let client: google.accounts.oauth2.TokenClient;
let accessToken: string;
let callback: undefined | ((token: string) => void);

const isStarted = () => !!client;

function init(): Promise<void> {
  return new Promise((resolve) => {
    const scriptTag = document.getElementById(TagId);
    if (scriptTag) {
      resolve();
      return;
    }
    const googleScriptTag = document.createElement('script');
    googleScriptTag.id = TagId;
    googleScriptTag.src = 'https://accounts.google.com/gsi/client';
    googleScriptTag.onload = () => {
      client = google.accounts.oauth2.initTokenClient({
        client_id: '786752256447-tp7agtt8i0e4c0eqvjtqneqnskq6ubj5.apps.googleusercontent.com',
        scope: RequiredScopes,
        callback: (response: google.accounts.oauth2.TokenResponse) => {
          if (response && response.access_token) {
            accessToken = response.access_token;
            if (callback) {
              callback(accessToken);
            }
          }
        },
      });
      resolve();
    };
    const titleTag = document.getElementsByTagName('title')[0];
    if (titleTag.parentNode) titleTag.parentNode.insertBefore(googleScriptTag, titleTag);
  });
}

function login(): Promise<string> {
  return new Promise((resolve) => {
    if (!isStarted()) {
      resolve('');
    }
    callback = (token: string) => {
      resolve(token);
      callback = undefined;
    };
    client.requestAccessToken();
  });
}

function getToken(): string {
  if (!isStarted()) {
    return '';
  }
  return accessToken;
}

function revokeAccess(token: string): Promise<void> {
  return new Promise((resolve) => {
    google.accounts.oauth2.revoke(token, () => {
      resolve();
    });
  });
}

async function logout() {
  if (!isStarted()) {
    return;
  }
  let token = getToken();
  if (!token) {
    token = await login();
  }
  await revokeAccess(token);
}

function getStatus() {
  if (!isStarted()) {
    return false;
  }
  return !!getToken();
}

const GoogleService = {
  getStatus,
  getToken,
  init,
  isStarted,
  login,
  logout,
};

export default GoogleService;
