import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

export function addListener(listener: (path: string) => void): () => void {
  const currentPath = history.createHref(history.location);
  listener(currentPath);
  return history.listen((location) => {
    const path = history.createHref(location);
    listener(path);
  });
}

/**
 * Function makes redirect to the same location but with updated state
 * it adds new entry to browser history keeping url same
 * @param state state that should be applied to location
 */
export function redirectWithState<S = any>(state: S) {
  const currentPath = history.location.pathname;
  history.push(currentPath, state);
}

export function getRefreshTokenHandler(opxp: boolean) {
  return () => {
    const opxpPrefix = opxp ? 'opxp' : '';
    const link = `${opxpPrefix}/login`;
    history.push(link);
  };
}

export default history;
