import { IAttribution } from '@web_opxp/features';
import { Action } from 'redux';
import { ApiResponse } from '../core/apiResponse';
import * as Types from './types';

export const AUTH_INIT = 'AUTH_INIT';
export type AUTH_INIT = typeof AUTH_INIT;
export interface IInitAuthAction extends Action<AUTH_INIT> {
  type: AUTH_INIT;
}

export const AUTH_RESTORE = 'AUTH_RESTORE';
export type AUTH_RESTORE = typeof AUTH_RESTORE;
export interface IRestoreAuthAction extends Action<AUTH_RESTORE> {
  type: AUTH_RESTORE;
}

export const AUTH_RESTORE_SUCCESS = 'AUTH_RESTORE_SUCCESS';
export type AUTH_RESTORE_SUCCESS = typeof AUTH_RESTORE_SUCCESS;
export interface IRestoreSuccessAuthAction extends Action<AUTH_RESTORE_SUCCESS> {
  type: AUTH_RESTORE_SUCCESS;
}

export const AUTH_RESTORE_FAIL = 'AUTH_RESTORE_FAIL';
export type AUTH_RESTORE_FAIL = typeof AUTH_RESTORE_FAIL;
export interface IRestoreFailedAuthAction extends Action<AUTH_RESTORE_FAIL> {
  type: AUTH_RESTORE_FAIL;
  reason?: string;
}

export const AUTH_SAVE_USER = 'AUTH_SAVE_USER';
export type AUTH_SAVE_USER = typeof AUTH_SAVE_USER;
export interface ISaveUserAuthAction extends Action<AUTH_SAVE_USER> {
  type: AUTH_SAVE_USER;
  user: Partial<Types.UserSession>;
}

export const AUTH_UPDATE_STATE = 'AUTH_UPDATE_STATE';
export type AUTH_UPDATE_STATE = typeof AUTH_UPDATE_STATE;
export interface IUpdateUserStateAction extends Action<AUTH_UPDATE_STATE> {
  type: AUTH_UPDATE_STATE;
  user: Partial<Types.UserSession>;
}

export const AUTH_USER_REMOVE = 'AUTH_USER_REMOVE';
export type AUTH_USER_REMOVE = typeof AUTH_USER_REMOVE;
export interface IRemoveUserAuthAction extends Action<AUTH_USER_REMOVE> {
  type: AUTH_USER_REMOVE;
  userId: number | null;
}

export const AUTH_LOGIN = 'AUTH_LOGIN';
export type AUTH_LOGIN = typeof AUTH_LOGIN;
export interface ILoginUserAction extends Action<AUTH_LOGIN> {
  type: AUTH_LOGIN;
  email: string;
  password: string;
}

export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export type AUTH_LOGIN_SUCCESS = typeof AUTH_LOGIN_SUCCESS;
export interface ILoginUserSuccessAction extends Action<AUTH_LOGIN_SUCCESS> {
  type: AUTH_LOGIN_SUCCESS;
}

export const AUTH_LOGIN_FAIL = 'AUTH_LOGIN_FAIL';
export type AUTH_LOGIN_FAIL = typeof AUTH_LOGIN_FAIL;
export interface ILoginUserFailAction extends Action<AUTH_LOGIN_FAIL> {
  type: AUTH_LOGIN_FAIL;
  error: string;
  couponInvalid?: boolean;
}

export const AUTH_SIGNUP = 'AUTH_SIGNUP';
export type AUTH_SIGNUP = typeof AUTH_SIGNUP;
export interface ISignupAction extends Action<AUTH_SIGNUP> {
  type: AUTH_SIGNUP;
  email: string;
  password: string;
  useBackUrl: boolean;
  attribution?: IAttribution;
}

export const AUTH_SIGNUP_SUCCESS = 'AUTH_SIGNUP_SUCCESS';
export type AUTH_SIGNUP_SUCCESS = typeof AUTH_SIGNUP_SUCCESS;
export interface ISignupSuccessAction extends Action<AUTH_SIGNUP_SUCCESS> {
  type: AUTH_SIGNUP_SUCCESS;
  response: ApiResponse;
}

export const AUTH_SIGNUP_FAIL = 'AUTH_SIGNUP_FAIL';
export type AUTH_SIGNUP_FAIL = typeof AUTH_SIGNUP_FAIL;
export interface ISignupFailAction extends Action<AUTH_SIGNUP_FAIL> {
  type: AUTH_SIGNUP_FAIL;
  error: ApiResponse;
}

export const AUTH_SESSION_EXPIRED = 'AUTH_SESSION_EXPIRED';
export type AUTH_SESSION_EXPIRED = typeof AUTH_SESSION_EXPIRED;
export interface ISessionExpiredAction extends Action<AUTH_SESSION_EXPIRED> {
  type: AUTH_SESSION_EXPIRED;
  error: ApiResponse;
}

export const AUTH_VALIDATE_TOKEN = 'AUTH_VALIDATE_TOKEN';
export type AUTH_VALIDATE_TOKEN = typeof AUTH_VALIDATE_TOKEN;
export interface IValidateTokenAction extends Action<AUTH_VALIDATE_TOKEN> {
  type: AUTH_VALIDATE_TOKEN;
  token: string;
  social?: boolean;
}

export const AUTH_VALIDATE_TOKEN_SUCCESS = 'AUTH_VALIDATE_TOKEN_SUCCESS';
export type AUTH_VALIDATE_TOKEN_SUCCESS = typeof AUTH_VALIDATE_TOKEN_SUCCESS;
export interface IValidateTokenSuccessAction extends Action<AUTH_VALIDATE_TOKEN_SUCCESS> {
  type: AUTH_VALIDATE_TOKEN_SUCCESS;
  sessionToken: string;
  userId: number;
}

export const AUTH_VALIDATE_TOKEN_FAIL = 'AUTH_VALIDATE_TOKEN_FAIL';
export type AUTH_VALIDATE_TOKEN_FAIL = typeof AUTH_VALIDATE_TOKEN_FAIL;
export interface IValidateTokenFailedAction extends Action<AUTH_VALIDATE_TOKEN_FAIL> {
  type: AUTH_VALIDATE_TOKEN_FAIL;
}

export const AUTH_SUBSCRIBE_USER = 'AUTH_SUBSCRIBE_USER';
export type AUTH_SUBSCRIBE_USER = typeof AUTH_SUBSCRIBE_USER;
export interface ISubscribeUserAction extends Action<AUTH_SUBSCRIBE_USER> {
  type: AUTH_SUBSCRIBE_USER;
  email: string;
}

export const AUTH_SUBSCRIBE_SUCCESS = 'AUTH_SUBSCRIBE_SUCCESS';
export type AUTH_SUBSCRIBE_SUCCESS = typeof AUTH_SUBSCRIBE_SUCCESS;
export interface ISubscribeUserSuccessAction extends Action<AUTH_SUBSCRIBE_SUCCESS> {
  type: AUTH_SUBSCRIBE_SUCCESS;
}

export const AUTH_SUBSCRIBE_FAIL = 'AUTH_SUBSCRIBE_FAIL';
export type AUTH_SUBSCRIBE_FAIL = typeof AUTH_SUBSCRIBE_FAIL;
export interface ISubscribeUserFailAction extends Action<AUTH_SUBSCRIBE_FAIL> {
  type: AUTH_SUBSCRIBE_FAIL;
}

export const AUTH_RESET = 'AUTH_RESET';
export type AUTH_RESET = typeof AUTH_RESET;
export interface IResetAuthAction extends Action<AUTH_RESET> {
  type: AUTH_RESET;
}

export const AUTH_COUPON = 'AUTH_COUPON';
export type AUTH_COUPON = typeof AUTH_COUPON;
export interface ICouponAction extends Action<AUTH_COUPON> {
  type: AUTH_COUPON;
  couponToken?: string;
}

export type IAuthActions =
  | IInitAuthAction
  | IRestoreAuthAction
  | IRestoreFailedAuthAction
  | ISaveUserAuthAction
  | IRestoreSuccessAuthAction
  | IRemoveUserAuthAction
  | ILoginUserAction
  | ILoginUserSuccessAction
  | ILoginUserFailAction
  | ISignupAction
  | ISignupSuccessAction
  | ISignupFailAction
  | IUpdateUserStateAction
  | ISessionExpiredAction
  | IValidateTokenAction
  | IValidateTokenFailedAction
  | IValidateTokenSuccessAction
  | ISubscribeUserAction
  | ISubscribeUserFailAction
  | ISubscribeUserSuccessAction
  | IResetAuthAction
  | ICouponAction;
