export interface User {
  userId: number;
  verified: boolean;
  email?: string;
  social?: boolean;
  accessToken: string;
  premium: boolean;
}

export interface UserSession extends User {
  session: string;
}

// map from user id to user session object
export interface SessionMap {
  [id: number]: User;
}

export interface AuthState {
  // currently logged in user
  userId: number | null;
  // all users who did not logged out in current browser
  users: SessionMap;
}

export enum ELogoutMode {
  // default mode - redirect to backUrl after successful logout
  Default = 1,
  // stop and wait for login
  WaitForLogin,
}

export interface IRole {
  name: string;
  id: number;
}

export enum ERole {
  PREAUTHENTICATED = 9,
  WEBPREMIUM = 12,
}
