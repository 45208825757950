import { IAttribution } from '@web_opxp/features';
import { ApiResponse } from '../core/apiResponse';
import * as ActionTypes from './actionTypes';
import * as Types from './types';

export const authInitAction: () => ActionTypes.IInitAuthAction = () => ({
  type: ActionTypes.AUTH_INIT,
});

export const authRestoreAction: () => ActionTypes.IRestoreAuthAction = () => ({
  type: ActionTypes.AUTH_RESTORE,
});

export const restoreAuthFailAction: (reason?: string) => ActionTypes.IRestoreFailedAuthAction = (reason) => ({
  type: ActionTypes.AUTH_RESTORE_FAIL,
  reason,
});

export const restoreAuthSuccessAction: () => ActionTypes.IRestoreSuccessAuthAction = () => ({
  type: ActionTypes.AUTH_RESTORE_SUCCESS,
});

export const saveUserAction: (user: Partial<Types.UserSession>) => ActionTypes.ISaveUserAuthAction = (user) => ({
  type: ActionTypes.AUTH_SAVE_USER,
  user,
});

export const updateUserAction: (user: Partial<Types.UserSession>) => ActionTypes.IUpdateUserStateAction = (user) => ({
  type: ActionTypes.AUTH_UPDATE_STATE,
  user,
});

export const removeUser: (userId: number) => ActionTypes.IRemoveUserAuthAction = (userId) => ({
  type: ActionTypes.AUTH_USER_REMOVE,
  userId,
});

export const loginAction: (email: string, password: string) => ActionTypes.ILoginUserAction = (email, password) => ({
  type: ActionTypes.AUTH_LOGIN,
  email,
  password,
});

export const loginFailedAction: (error: string, couponInvalid?: boolean) => ActionTypes.ILoginUserFailAction = (
  error,
  couponInvalid
) => ({
  type: ActionTypes.AUTH_LOGIN_FAIL,
  error,
  couponInvalid,
});

export const loginSuccessAction: () => ActionTypes.ILoginUserSuccessAction = () => ({
  type: ActionTypes.AUTH_LOGIN_SUCCESS,
});

export const signupAction: (
  email: string,
  password: string,
  useBackUrl?: boolean,
  attribution?: IAttribution
) => ActionTypes.ISignupAction = (email, password, useBackUrl = false, attribution) => ({
  type: ActionTypes.AUTH_SIGNUP,
  email,
  password,
  useBackUrl,
  attribution,
});

export const signupSuccessAction: (response: ApiResponse) => ActionTypes.ISignupSuccessAction = (response) => ({
  type: ActionTypes.AUTH_SIGNUP_SUCCESS,
  response,
});

export const signupFailAction: (error: ApiResponse) => ActionTypes.ISignupFailAction = (error) => ({
  type: ActionTypes.AUTH_SIGNUP_FAIL,
  error,
});

export const sessionExpired: (error: ApiResponse) => ActionTypes.ISessionExpiredAction = (error) => ({
  type: ActionTypes.AUTH_SESSION_EXPIRED,
  error,
});

export const validateTokenAction: (token: string, social?: boolean) => ActionTypes.IValidateTokenAction = (
  token,
  social
) => ({
  type: ActionTypes.AUTH_VALIDATE_TOKEN,
  token,
  social,
});

export const validateTokenSuccessAction: (
  userId: number,
  sessionToken: string
) => ActionTypes.IValidateTokenSuccessAction = (userId, sessionToken) => ({
  type: ActionTypes.AUTH_VALIDATE_TOKEN_SUCCESS,
  userId,
  sessionToken,
});

export const validateTokenFailAction: () => ActionTypes.IValidateTokenFailedAction = () => ({
  type: ActionTypes.AUTH_VALIDATE_TOKEN_FAIL,
});

export const subscribeUserAction: (email: string) => ActionTypes.ISubscribeUserAction = (email) => ({
  type: ActionTypes.AUTH_SUBSCRIBE_USER,
  email,
});

export const subscribeUserSuccessAction: () => ActionTypes.ISubscribeUserSuccessAction = () => ({
  type: ActionTypes.AUTH_SUBSCRIBE_SUCCESS,
});

export const subscribeUserFailAction: () => ActionTypes.ISubscribeUserFailAction = () => ({
  type: ActionTypes.AUTH_SUBSCRIBE_FAIL,
});

export const resetAction: () => ActionTypes.IResetAuthAction = () => ({
  type: ActionTypes.AUTH_RESET,
});

export const updateCouponAction: (couponToken?: string) => ActionTypes.ICouponAction = (couponToken) => ({
  type: ActionTypes.AUTH_COUPON,
  couponToken,
});
