import { FBStatusResponse } from '../types';

declare global {
  interface Window {
    FB: facebook.FacebookStatic;
  }
}

export function isStarted(): boolean {
  // check if FB instance was created
  return !!window['FB'];
}

export function getStatus(): Promise<FBStatusResponse> {
  return new Promise((resolve) => {
    if (!isStarted()) {
      resolve(null);
    }
    FB.getLoginStatus(resolve);
  });
}

export function login(): Promise<FBStatusResponse> {
  return new Promise((resolve) => {
    if (!isStarted()) {
      resolve(null);
    }
    FB.login(resolve, { scope: 'public_profile,email', return_scopes: true });
  });
}

export function logout(): Promise<FBStatusResponse> {
  return new Promise((resolve) => {
    if (!isStarted()) {
      resolve(null);
    }
    FB.logout(resolve);
  });
}

const FBService = {
  getStatus,
  login,
  logout,
};
export default FBService;
