import { Button, EButtonColor } from '@web_opxp/components';
import { useTranslation } from '@web_opxp/features';
import * as React from 'react';
import { connect } from 'react-redux';
import AdblockModal from './AdblockModal';
import { loginFacebookAction } from './store/actions';

interface IProps {
  onClick: () => void;
}

export const OpxpFacebookButtonFC: React.FC<IProps> = ({ onClick }) => {
  const { t } = useTranslation();
  const [isDialogOpen, toggleDialog] = React.useState(false);
  const handleDialogOpen = () => toggleDialog(true);
  const handleDialogClose = () => toggleDialog(false);

  const clickHandler = () => {
    if (!window['FB']) {
      handleDialogOpen();
    } else onClick();
  };
  const label = t('Social.Facebook');
  const btnContent = (
    <>
      <svg
        className="social-icon facebook"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M13.1875 8.90625V6.03125C13.1875 5.23775 13.8315 4.59375 14.625 4.59375H16.0625V1H13.1875C10.8056 1 8.875 2.93056 8.875 5.3125V8.90625H6V12.5H8.875V24H13.1875V12.5H16.0625L17.5 8.90625H13.1875Z" />
      </svg>
      {label}
    </>
  );
  return (
    <>
      <Button className="opxp-social-button" color={EButtonColor.white} label={btnContent} onClick={clickHandler} />
      {isDialogOpen && <AdblockModal onClose={handleDialogClose} />}
    </>
  );
};

export const mapDispatchToProps = {
  onClick: loginFacebookAction,
};

const OpxpFacebookButton = connect(null, mapDispatchToProps)(OpxpFacebookButtonFC);
export default OpxpFacebookButton;
