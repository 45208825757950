import * as ActionTypes from './actionTypes';

export interface IFeatureState {
  userId: number | null;
  verified: boolean;
  session?: string;
  email?: string;
  social?: boolean;
  accessToken: string;
  premium: boolean;
  couponToken?: string;
}

export const initialState: IFeatureState = {
  userId: null,
  verified: false,
  accessToken: '',
  premium: false,
};

export const statePropName = 'auth';

export function featureReducer(state: IFeatureState = initialState, action: ActionTypes.IAuthActions): IFeatureState {
  switch (action.type) {
    case ActionTypes.AUTH_SAVE_USER: {
      return { ...state, ...action.user };
    }
    case ActionTypes.AUTH_COUPON: {
      return {
        ...state,
        couponToken: action.couponToken,
      };
    }
  }
  return state;
}
