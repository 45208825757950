import { Action } from 'redux';

import { SocialAuthProvider } from '../types';

export const SOCIAL_RESTORE_REQUEST = 'SOCIAL_RESTORE_REQUEST';
export type SOCIAL_RESTORE_REQUEST = typeof SOCIAL_RESTORE_REQUEST;
export interface IRestoreSocialAuthRequestAction extends Action<SOCIAL_RESTORE_REQUEST> {
  type: SOCIAL_RESTORE_REQUEST;
}

export const SOCIAL_RESTORE_REQUEST_SUCCESS = 'SOCIAL_RESTORE_REQUEST_SUCCESS';
export type SOCIAL_RESTORE_REQUEST_SUCCESS = typeof SOCIAL_RESTORE_REQUEST_SUCCESS;
export interface IRestoreSocialRequestSuccessAction extends Action<SOCIAL_RESTORE_REQUEST_SUCCESS> {
  type: SOCIAL_RESTORE_REQUEST_SUCCESS;
  provider: SocialAuthProvider;
  token: string;
}

export const SOCIAL_RESTORE_SUCCESS = 'SOCIAL_RESTORE_SUCCESS';
export type SOCIAL_RESTORE_SUCCESS = typeof SOCIAL_RESTORE_SUCCESS;
export interface IRestoreSocialSuccessAction extends Action<SOCIAL_RESTORE_SUCCESS> {
  type: SOCIAL_RESTORE_SUCCESS;
  accessToken: string;
  provider: SocialAuthProvider;
}

export const SOCIAL_RESTORE_FAIL = 'SOCIAL_RESTORE_FAIL';
export type SOCIAL_RESTORE_FAIL = typeof SOCIAL_RESTORE_FAIL;
export interface IRestoreSocialFailAction extends Action<SOCIAL_RESTORE_FAIL> {
  type: SOCIAL_RESTORE_FAIL;
}

export type ISocialRestoreResult = IRestoreSocialFailAction | IRestoreSocialSuccessAction;

export const SOCIAL_LOGIN_FACEBOOK = 'SOCIAL_LOGIN_FACEBOOK';
export type SOCIAL_LOGIN_FACEBOOK = typeof SOCIAL_LOGIN_FACEBOOK;
export interface ILoginFacebookAction extends Action<SOCIAL_LOGIN_FACEBOOK> {
  type: SOCIAL_LOGIN_FACEBOOK;
}

export const SOCIAL_LOGIN_REQUEST_START = 'SOCIAL_LOGIN_REQUEST_START';
export type SOCIAL_LOGIN_REQUEST_START = typeof SOCIAL_LOGIN_REQUEST_START;
export interface ISocialLoginStartAction extends Action<SOCIAL_LOGIN_REQUEST_START> {
  type: SOCIAL_LOGIN_REQUEST_START;
}

export const SOCIAL_LOGIN_REQUEST_SUCCESS = 'SOCIAL_LOGIN_REQUEST_SUCCESS';
export type SOCIAL_LOGIN_REQUEST_SUCCESS = typeof SOCIAL_LOGIN_REQUEST_SUCCESS;
export interface ILoginSocialRequestSuccessAction extends Action<SOCIAL_LOGIN_REQUEST_SUCCESS> {
  type: SOCIAL_LOGIN_REQUEST_SUCCESS;
  provider: SocialAuthProvider;
  token: string;
}

export const SOCIAL_LOGIN_FAIL = 'SOCIAL_LOGIN_FAIL';
export type SOCIAL_LOGIN_FAIL = typeof SOCIAL_LOGIN_FAIL;
export interface ILoginSocialFailAction extends Action<SOCIAL_LOGIN_FAIL> {
  type: SOCIAL_LOGIN_FAIL;
  provider: SocialAuthProvider;
  reason?: string;
  couponInvalid?: boolean;
}

export const SOCIAL_LOGIN_GOOGLE = 'SOCIAL_LOGIN_GOOGLE';
export type SOCIAL_LOGIN_GOOGLE = typeof SOCIAL_LOGIN_GOOGLE;
export interface ILoginGoogleAction extends Action<SOCIAL_LOGIN_GOOGLE> {
  type: SOCIAL_LOGIN_GOOGLE;
}

export const SOCIAL_LOGOUT = 'SOCIAL_LOGOUT';
export type SOCIAL_LOGOUT = typeof SOCIAL_LOGOUT;
export interface ILogoutAction extends Action<SOCIAL_LOGOUT> {
  type: SOCIAL_LOGOUT;
}

export const SOCIAL_LOGOUT_COMPLETE = 'SOCIAL_LOGOUT_COMPLETE';
export type SOCIAL_LOGOUT_COMPLETE = typeof SOCIAL_LOGOUT_COMPLETE;
export interface ILogoutCompleteAction extends Action<SOCIAL_LOGOUT_COMPLETE> {
  type: SOCIAL_LOGOUT_COMPLETE;
}

export const SOCIAL_LOGIN_APPLE = 'SOCIAL_LOGIN_APPLE';
export type SOCIAL_LOGIN_APPLE = typeof SOCIAL_LOGIN_APPLE;
export interface ILoginAppleAction extends Action<SOCIAL_LOGIN_APPLE> {
  type: SOCIAL_LOGIN_APPLE;
}

export type ISocialActions =
  | IRestoreSocialAuthRequestAction
  | IRestoreSocialRequestSuccessAction
  | ISocialRestoreResult
  | ISocialLoginStartAction
  | ILoginFacebookAction
  | ILoginSocialFailAction
  | ILoginGoogleAction
  | ILogoutAction
  | ILogoutCompleteAction
  | ILoginAppleAction;
