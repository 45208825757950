import { isProduction } from '../../services/urlManager';

const TagId = 'apple-auth';
const AppleServiceID = 'com.neuronation.neuronation';

export function isStarted(): boolean {
  return !!window['AppleID'];
}

export async function login(): Promise<AppleSignInAPI.SignInResponseI> {
  await init();
  return AppleID.auth.signIn();
}

function init(): Promise<void> {
  return new Promise((resolve) => {
    const scriptTag = document.getElementById(TagId);
    if (scriptTag || isStarted()) {
      resolve();
      return;
    }
    const appleScriptTag = document.createElement('script');
    appleScriptTag.id = TagId;
    appleScriptTag.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
    appleScriptTag.onload = () => {
      const currentHostname = window.location.hostname;
      const redirectURI = 'https://' + (isProduction ? currentHostname : 'sso.dev.neuronation.com');
      AppleID.auth.init({
        clientId: AppleServiceID,
        scope: 'email',
        redirectURI,
        usePopup: true,
      });
      resolve();
    };
    const titleTag = document.getElementsByTagName('title')[0];
    if (titleTag.parentNode) titleTag.parentNode.insertBefore(appleScriptTag, titleTag);
  });
}
