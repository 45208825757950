import { SocialAuthProvider } from '../types';
import * as ActionTypes from './actionTypes';

// restore social auth request
export const restoreSocialAuthRequestAction: () => ActionTypes.IRestoreSocialAuthRequestAction = () => ({
  type: ActionTypes.SOCIAL_RESTORE_REQUEST,
});

// restore social auth request succeeded
export const restoreSocialRequestSuccessAction: (
  provider: SocialAuthProvider,
  token: string
) => ActionTypes.IRestoreSocialRequestSuccessAction = (provider, token) => ({
  type: ActionTypes.SOCIAL_RESTORE_REQUEST_SUCCESS,
  provider,
  token,
});

/**
 * restore social auth request failed
 */
export const restoreSocialAuthFailAction: () => ActionTypes.IRestoreSocialFailAction = () => ({
  type: ActionTypes.SOCIAL_RESTORE_FAIL,
});

// restore social auth succeeded and synced with backend
export const restoreSocialAuthSuccessAction: (
  accessToken: string,
  provider: SocialAuthProvider
) => ActionTypes.IRestoreSocialSuccessAction = (accessToken, provider) => ({
  type: ActionTypes.SOCIAL_RESTORE_SUCCESS,
  accessToken,
  provider,
});

// user wants to login with facebook
export const loginFacebookAction: () => ActionTypes.ILoginFacebookAction = () => ({
  type: ActionTypes.SOCIAL_LOGIN_FACEBOOK,
});

// social login flow started
export const socialLoginStartAction: () => ActionTypes.ISocialLoginStartAction = () => ({
  type: ActionTypes.SOCIAL_LOGIN_REQUEST_START,
});

// social auth login request succeeded
export const loginSocialRequestSuccessAction: (
  provider: SocialAuthProvider,
  token: string
) => ActionTypes.ILoginSocialRequestSuccessAction = (provider, token) => ({
  type: ActionTypes.SOCIAL_LOGIN_REQUEST_SUCCESS,
  provider,
  token,
});

// social auth login failed
export const loginSocialFailAction: (
  provider: SocialAuthProvider,
  reason?: string,
  couponInvalid?: boolean
) => ActionTypes.ILoginSocialFailAction = (provider, reason, couponInvalid) => ({
  type: ActionTypes.SOCIAL_LOGIN_FAIL,
  provider,
  reason,
  couponInvalid,
});

// user wants to login with google
export const loginGoogleAction: () => ActionTypes.ILoginGoogleAction = () => ({
  type: ActionTypes.SOCIAL_LOGIN_GOOGLE,
});

// user wants to logout
export const socialLogoutAction: () => ActionTypes.ILogoutAction = () => ({
  type: ActionTypes.SOCIAL_LOGOUT,
});

// social logout completed
export const socialLogoutCompleteAction: () => ActionTypes.ILogoutCompleteAction = () => ({
  type: ActionTypes.SOCIAL_LOGOUT_COMPLETE,
});

// user wants to login with apple
export const loginAppleAction: () => ActionTypes.ILoginAppleAction = () => ({
  type: ActionTypes.SOCIAL_LOGIN_APPLE,
});
