import { useDoubleClickProtection } from '@web_opxp/components';
import { useTranslation } from '@web_opxp/features';
import { connect } from 'react-redux';
import GoogleButtonImage from './google.png';
import { loginGoogleAction } from './store/actions';

interface Props {
  onClick: () => void;
}

export function GoogleButtonFC({ onClick }: Props) {
  const { t } = useTranslation();
  const onClickHandler = useDoubleClickProtection(onClick);
  return (
    <div className="mx-1 flex-fill">
      <button onClick={onClickHandler} type="button" className="btn btn-block btn-lg btn-light social-button">
        <img className="facebook-button" src={GoogleButtonImage} alt={t('Social.Google')} />
      </button>
    </div>
  );
}

export const mapDispatchToProps = {
  onClick: loginGoogleAction,
};

const GoogleButton = connect(null, mapDispatchToProps)(GoogleButtonFC);
export default GoogleButton;
