import { useDoubleClickProtection } from '@web_opxp/components';
import { useTranslation } from '@web_opxp/features';
import * as React from 'react';
import { connect } from 'react-redux';
import AdblockModal from './AdblockModal';
import FBButtonImage from './FB.png';
import { loginFacebookAction } from './store/actions';

interface Props {
  onClick: () => void;
}

export function FacebookButtonFC({ onClick }: Props) {
  const { t } = useTranslation();
  const [isDialogOpen, toggleDialog] = React.useState(false);
  const handleDialogOpen = () => toggleDialog(true);
  const handleDialogClose = () => toggleDialog(false);

  const clickHandler = () => {
    if (!window['FB']) {
      handleDialogOpen();
    } else onClick();
  };
  const onClickHandler = useDoubleClickProtection(clickHandler);
  return (
    <>
      <div className="mx-1 flex-fill">
        <button onClick={onClickHandler} type="button" className="btn btn-block btn-lg btn-light social-button">
          <img className="facebook-button" src={FBButtonImage} alt={t('Social.Facebook')} />
        </button>
      </div>
      {isDialogOpen && <AdblockModal onClose={handleDialogClose} />}
    </>
  );
}

export const mapDispatchToProps = {
  onClick: loginFacebookAction,
};

const FacebookButton = connect(null, mapDispatchToProps)(FacebookButtonFC);
export default FacebookButton;
