import { SentryService, isLocalhost, isProduction } from '@web_opxp/features';
import ReactDOM from 'react-dom';
import Root from './Root';
import { BuildID } from './core/env';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

if (!isLocalhost) {
  SentryService.initSentry(isProduction(), BuildID);
}

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
