import { AttributionService } from '@web_opxp/features';
import { all, call, delay, put, race, select, takeEvery } from 'redux-saga/effects';
import { ApiService, HttpError } from 'web_core_library';
import { AuthSelectors } from '../../auth';
import { ApiResponse } from '../../core/apiResponse';
import { HttpStatusCode } from '../../core/constants';
import { FacebookLogoutTimeout, SOCIAL_LOGIN_TIMEOUT_MS, SOCIAL_STATUS_TIMEOUT_MS } from '../constants';
import * as AppleService from '../services/appleService';
import FBService from '../services/fbService';
import GoogleService from '../services/googleService';
import SocialService from '../services/socialService';
import { FBStatusResponse, SocialAuthProvider } from '../types';
import * as ActionTypes from './actionTypes';
import * as Actions from './actions';

export function* restoreSocialAuthSaga() {
  // try to restore facebook auth
  const fbStatus: FBStatusResponse = yield call(tryToGetFacebookStatus);
  if (fbStatus && fbStatus.status === 'connected' && fbStatus.authResponse.accessToken) {
    // facebook login succeeded
    yield put(Actions.restoreSocialRequestSuccessAction('Facebook', fbStatus.authResponse.accessToken));
    return;
  }
  const googleSignedIn: boolean = yield call(tryToGetGoogleStatus);
  if (googleSignedIn) {
    // google login succeeded
    const accessToken: string = yield call(GoogleService.getToken);
    yield put(Actions.restoreSocialRequestSuccessAction('Google', accessToken));
    return;
  }
  // all social auth requests have failed
  yield put(Actions.restoreSocialAuthFailAction());
}

export function* socialLoginApiSaga({ token, provider }: ActionTypes.IRestoreSocialRequestSuccessAction) {
  try {
    const loginResponse: ApiResponse = yield call(socialLoginApiCallSaga, token, provider);
    if (loginResponse.isError()) {
      throw loginResponse;
    }
    const accessToken = loginResponse.data.result.accessToken;
    yield put(Actions.restoreSocialAuthSuccessAction(accessToken, provider));
  } catch (error) {
    let message;
    let couponInvalid = false;
    if ((error as Error).message) {
      message = (error as Error).message;
    } else {
      message = (error as ApiResponse).data.message;
      couponInvalid = (error as ApiResponse).data.couponInvalid;
    }
    yield put(Actions.loginSocialFailAction(provider, message, couponInvalid));
    yield put(Actions.restoreSocialAuthFailAction());
  }
}

export function* socialLoginApiCallSaga(token: string, provider: SocialAuthProvider) {
  try {
    const couponToken = yield select(AuthSelectors.getCouponToken);
    const attribution: ReturnType<typeof AttributionService.getAttribution> = yield call(
      AttributionService.getAttribution
    );
    yield call(SocialService.init, ApiService);
    const response = yield call(SocialService.socialLogin, token, provider, attribution, couponToken);
    return new ApiResponse(response);
  } catch (error) {
    const response = new ApiResponse(error as HttpError);
    let message = '';
    let couponInvalid = false;
    switch (response.status) {
      case HttpStatusCode.NotFound:
        message = 'opxp.prereg:Coupon.Error.NotFound';
        couponInvalid = true;
        break;
      case HttpStatusCode.InvalidData: {
        const errorMessage = response.data.message || response.data.msg;
        switch (errorMessage) {
          case 'Coupon is already reserved.':
            message = 'opxp.prereg:Coupon.Error.Reserved';
            couponInvalid = true;
            break;
          case 'Coupon could not be reserved.':
            message = 'opxp.prereg:Coupon.Error.Invalid';
            couponInvalid = true;
            break;
          case 'User is already premium.':
            message = 'opxp.prereg:Coupon.Error.Premium';
            couponInvalid = true;
            break;
          case 'Coupon reserved time is expired':
            message = 'opxp.prereg:Coupon.Error.Expired';
            couponInvalid = true;
            break;
          case 'mail_cannot_be_empty.':
            message = 'common.auth:Social.NoEmailError';
            break;
          default:
            message = errorMessage;
            break;
        }
        break;
      }
      case HttpStatusCode.InternalError:
        message = response.data;
        break;
      default:
        message = '';
        break;
    }
    response.data = { message, couponInvalid };
    return response;
  }
}

export function* loginFacebook() {
  yield put(Actions.socialLoginStartAction());
  // try to login via facebook auth
  const [fbStatus]: [FBStatusResponse | undefined, unknown] = yield race([
    call([FBService, 'login']),
    delay(SOCIAL_LOGIN_TIMEOUT_MS),
  ]);
  if (fbStatus && fbStatus.status === 'connected' && fbStatus.authResponse.accessToken) {
    // facebook login succeeded
    yield put(Actions.loginSocialRequestSuccessAction('Facebook', fbStatus.authResponse.accessToken));
    return;
  }
  if (fbStatus === null) {
    yield put(Actions.loginSocialFailAction('Facebook', 'common:Errors.Blocked'));
    return;
  }
  yield put(Actions.loginSocialFailAction('Facebook'));
}

export function* loginGoogle() {
  yield put(Actions.socialLoginStartAction());
  // try to login via google auth2
  try {
    yield call(waitForGoogleServiceToStart);
    const [accessToken]: [string, unknown] = yield race([call(GoogleService.login), delay(SOCIAL_LOGIN_TIMEOUT_MS)]);
    if (!accessToken) {
      yield put(Actions.loginSocialFailAction('Google'));
      return;
    }
    // google login succeeded
    yield put(Actions.loginSocialRequestSuccessAction('Google', accessToken));
  } catch (error) {
    yield put(Actions.loginSocialFailAction('Google'));
  }
}

export function* logoutSaga() {
  const fbStatus: FBStatusResponse = yield call(tryToGetFacebookStatus);
  if (fbStatus && fbStatus.status === 'connected') {
    // facebook authorized - logout
    yield race([call([FBService, 'logout']), delay(FacebookLogoutTimeout)]);
  }
  yield call(waitForGoogleServiceToStart);
  const googleSignedIn: boolean = yield call(tryToGetGoogleStatus);
  if (googleSignedIn) {
    // google authorized
    yield call(GoogleService.logout);
  }
  yield put(Actions.socialLogoutCompleteAction());
}

export function* waitForGoogleServiceToStart() {
  yield call(GoogleService.init);
  let isStarted = yield call(GoogleService.isStarted);
  let trials = 0;
  while (!isStarted && trials < 10) {
    // wait until google auth library has started
    yield delay(100);
    isStarted = yield call(GoogleService.isStarted);
    trials = trials + 1;
  }
}

export function* tryToGetFacebookStatus() {
  const [fbStatus]: [FBStatusResponse, unknown] = yield race([
    call([FBService, 'getStatus']),
    delay(SOCIAL_STATUS_TIMEOUT_MS),
  ]);
  if (!fbStatus) {
    // facebook failed to get status in time
    return null;
  }
  return fbStatus;
}

export function* tryToGetGoogleStatus() {
  const googleSignedIn: boolean = yield call(GoogleService.getStatus);
  return !!googleSignedIn;
}

export function* loginAppleSaga() {
  yield put(Actions.socialLoginStartAction());
  try {
    const [auth]: [AppleSignInAPI.SignInResponseI, unknown] = yield race([
      call(AppleService.login),
      delay(SOCIAL_LOGIN_TIMEOUT_MS),
    ]);
    if (!auth) {
      yield put(Actions.loginSocialFailAction('Apple'));
      return;
    }
    const token = auth.authorization.id_token;
    if (!token) {
      yield put(Actions.loginSocialFailAction('Apple'));
      return;
    }
    yield put(Actions.loginSocialRequestSuccessAction('Apple', token));
  } catch (error) {
    yield put(Actions.loginSocialFailAction('Apple'));
  }
}

export default function* socialSagaWatcher() {
  yield all([
    takeEvery(ActionTypes.SOCIAL_RESTORE_REQUEST, restoreSocialAuthSaga),
    takeEvery(
      [ActionTypes.SOCIAL_RESTORE_REQUEST_SUCCESS, ActionTypes.SOCIAL_LOGIN_REQUEST_SUCCESS],
      socialLoginApiSaga
    ),
    takeEvery(ActionTypes.SOCIAL_LOGIN_FACEBOOK, loginFacebook),
    takeEvery(ActionTypes.SOCIAL_LOGIN_GOOGLE, loginGoogle),
    takeEvery(ActionTypes.SOCIAL_LOGIN_APPLE, loginAppleSaga),
    takeEvery([ActionTypes.SOCIAL_LOGOUT, ActionTypes.SOCIAL_LOGIN_FAIL], logoutSaga),
  ]);
}
