import { IAttribution } from '@web_opxp/features';
import { IApiService } from 'web_core_library';
import urlManager from '../../services/urlManager';
import { SocialAuthProvider } from '../types';

/**
 * API calls related to signup feature
 */

export function createSocialService() {
  let ApiService: IApiService;

  /**
   * request to sync social login with backend
   * id: 'login_social'
   * method: 'post'
   * @param token access token from social auth provider
   * @param provider social provider = 'facebook' or 'google'
   */
  const socialLogin = (
    token: string,
    provider: SocialAuthProvider,
    attribution?: IAttribution,
    couponToken?: string
  ) => {
    // TODO: this needs to be refactored to use language feature somehow
    // either additional parameter should be introduced
    // or global service implemented
    const language = localStorage.getItem('lang');
    const referTo = urlManager.getBackUrl();
    const data = {
      provider: provider.toLowerCase(),
      token,
      referTo,
      language,
      attribution,
      couponToken,
    };
    return ApiService.action('login_social', {
      data,
      method: 'post',
    });
  };

  const service = {
    init: (ApiServiceInstance: IApiService) => {
      ApiService = ApiServiceInstance;
    },
    socialLogin,
  };
  return service;
}

const SocialService = createSocialService();
export default SocialService;
