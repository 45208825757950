import * as Reducer from './reducer';
import * as Types from './types';

export interface IAppState {
  // this feature state
  [Reducer.statePropName]: Reducer.IFeatureState;
  // other features we don't care of
  [otherKeys: string]: any;
}

export const getFeatureState = (state: IAppState) => state[Reducer.statePropName];

export const getUser = (state: IAppState) => {
  const { verified, userId, email, social } = getFeatureState(state);
  return {
    verified,
    userId,
    email,
    social,
  } as Types.User;
};

export const getToken = (state: IAppState) => getFeatureState(state).session;

export const getAccessToken = (state: IAppState) => getFeatureState(state).accessToken;

export const isUserRestored = (state: IAppState) => !!getToken(state);

export const isUserVerified = (state: IAppState) => getFeatureState(state).verified;

export const isUserPremium = (state: IAppState) => getFeatureState(state).premium;

export const getUserId = (state: IAppState) => getFeatureState(state).userId;

export const getUserEmail = (state: IAppState) => getFeatureState(state).email;

export const getCouponToken = (state: IAppState) => getFeatureState(state).couponToken;
