import { useTranslation } from '@web_opxp/features';
import * as React from 'react';
import './AdblockModal.scss';

export interface IAdblockModalProps {
  onClose: () => void;
}

const AdblockModal: React.FC<IAdblockModalProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const errorMessage = t('common.common:Adblock_Modal.Message');
  return (
    <>
      <div className="adblock-modal-overlay" />
      <div className="adblock-modal">
        <div className="modal-header">
          <h2>{t('common.common:Adblock_Modal.Header')}</h2>
          <span
            className="close-button"
            role="button"
            onClick={() => onClose()}
            title={t('common.common:Adblock-Modal.Close')}>
            x
          </span>
        </div>
        <p className="modal-message">{errorMessage}</p>
      </div>
    </>
  );
};

export default AdblockModal;
