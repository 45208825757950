import * as React from 'react';
import './LoadingIndicator.scss';

export default function LoadingIndicator() {
  return (
    <div className="loading-indicator">
      <img
        className="spin"
        alt="loading indicator"
        src="https://cms.cdn.neuronation.com/assets/opxp/onboarding/asset_loader.png"
      />
    </div>
  );
}
