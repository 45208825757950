import * as React from 'react';

export enum Themes {
  Edison = 'edison',
  Default = 'legacy',
  Opxp = 'opxp',
}

function loadLegacyTheme() {
  import('../styles/themes/theme.legacy');
}

function loadEdisonTheme() {
  import('../styles/themes/theme.edison');
}

function loadOpxpTheme() {
  import('../styles/themes/theme.opxp');
}

export function loadTheme(theme: Themes) {
  switch (theme) {
    case Themes.Opxp:
      loadOpxpTheme();
      break;
    case Themes.Edison:
      loadEdisonTheme();
      break;
    default:
      loadLegacyTheme();
      break;
  }
}

const ThemeContext = React.createContext(Themes.Default);
export const ThemeProvider = ThemeContext.Provider;
export const ThemeConsumer = ThemeContext.Consumer;
