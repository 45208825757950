import { AxiosError, AxiosResponse } from 'axios';

import { HttpError } from 'web_core_library';
import { HttpStatusCode } from './constants';

function instanceOfAxiosError(object: any): object is AxiosError {
  return 'response' in object;
}

function instanceOfHttpError(object: any): object is HttpError {
  return 'baseError' in object;
}

/**
 * base response class
 */
export class ApiResponse<P = any> {
  public data: P | string;

  public status: number;

  constructor(response: AxiosResponse<P> | AxiosError | Error | HttpError) {
    if (response instanceof Error && !instanceOfAxiosError(response) && !instanceOfHttpError(response)) {
      this.status = HttpStatusCode.InternalError;
      this.data = response.message;
    } else {
      const realResponse = instanceOfAxiosError(response) ? (response.response as AxiosResponse) : response;
      // realResponse is undefined for network errors otherwise it represents the response
      this.status = realResponse ? realResponse.status : HttpStatusCode.Unknown;
      this.data = realResponse ? realResponse.data : {};
    }
  }

  public isSuccess() {
    return this.status > 0 && this.status < 300;
  }

  public isError() {
    return !this.isSuccess();
  }
}
