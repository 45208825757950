import { Button, EButtonColor } from '@web_opxp/components';
import { useTranslation } from '@web_opxp/features';
import * as React from 'react';
import { connect } from 'react-redux';
import { loginGoogleAction } from './store/actions';

interface IProps {
  onClick: () => void;
}

export const OpxpGoogleButtonFC: React.FC<IProps> = ({ onClick }) => {
  const { t } = useTranslation();
  const label = t('Social.Google');
  const btnContent = (
    <>
      <svg
        className="social-icon google"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M5.32486 9.05008L1.23717 6.69006C0.447328 8.28621 4.6875e-05 10.0863 0 12.0001C0 13.8874 0.452109 15.6906 1.25409 17.3009L5.32458 14.9509C4.92309 14.0468 4.69561 13.0497 4.69566 12.0001C4.6957 10.9506 4.92333 9.95379 5.32486 9.05008Z" />
        <path d="M19.8716 2.94219C17.7627 1.10779 15.0069 -0.00207392 11.9919 -5.82944e-05C7.86681 0.00270733 4.18496 2.10861 2.02173 5.33544L6.11274 7.69747C7.4431 5.88275 9.58337 4.69555 12.0003 4.6956C13.7622 4.69565 15.3773 5.32546 16.6388 6.36908C16.9476 6.6245 17.399 6.60111 17.6823 6.31775L19.9104 4.08974C20.2316 3.76846 20.2144 3.24032 19.8716 2.94219Z" />
        <path d="M23.918 10.6116C23.8713 10.2143 23.5373 9.9136 23.1374 9.9136L13.3046 9.91309C12.8724 9.91309 12.522 10.2635 12.522 10.6957V13.8235C12.522 14.2557 12.8724 14.6062 13.3046 14.6062H18.8147C18.3071 15.9271 17.4256 17.0626 16.3033 17.888L18.6437 21.9417C20.9995 20.3453 22.7906 17.9317 23.5795 15.0824C24.0084 13.5332 24.0856 12.0352 23.918 10.6116Z" />
        <path d="M14.9495 18.6743C14.0469 19.0764 13.0505 19.3045 11.9998 19.3046C9.58013 19.3046 7.44109 18.1174 6.11166 16.3034L2.03809 18.6553C4.18318 21.8504 7.82377 24.0002 11.9997 24.0002C13.8874 24.0002 15.6842 23.5387 17.2914 22.7306L14.9495 18.6743Z" />
      </svg>
      {label}
    </>
  );
  return <Button className="opxp-social-button" color={EButtonColor.white} label={btnContent} onClick={onClick} />;
};

export const mapDispatchToProps = {
  onClick: loginGoogleAction,
};

const OpxpGoogleButton = connect(null, mapDispatchToProps)(OpxpGoogleButtonFC);
export default OpxpGoogleButton;
