import * as React from 'react';
import LoadingIndicator from '../../apps/common/LoadingIndicator';
// styles imports
import './Loading.scss';

export interface Props {
  error: Error | null;
}

export default function Loading() {
  return (
    <div className="loading-spinner">
      <img
        className="neuronation-logo"
        alt="NeuroNation logo"
        src="https://cms.cdn.neuronation.com/assets/opxp/logo.svg"
      />
      <LoadingIndicator />
    </div>
  );
}
