import { IAttribution } from '@web_opxp/features';
import { IApiService } from 'web_core_library';

export function createAuthService() {
  let ApiService: IApiService;

  const validate = (token: string) => {
    if (!token) {
      throw new Error('Can not validate empty token!');
    }
    return ApiService.action('validate', {
      method: 'post',
      token,
    });
  };

  const getRoles = (userId: number) => {
    return ApiService.action('users', {
      method: 'get',
      params: {
        fields: 'roles',
      },
      urlParams: {
        userId,
      },
    });
  };

  const login = (mail: string, password: string, couponToken?: string) => {
    return ApiService.action('login', {
      data: {
        mail,
        password,
        couponToken,
      },
      method: 'post',
    });
  };

  const signup = (
    mail: string,
    password: string,
    language: string,
    referTo: string,
    attribution?: IAttribution,
    couponToken?: string
  ) => {
    const timezone = new Date().getTimezoneOffset() * -60;
    const data = {
      mail,
      password,
      language,
      timezone,
      referTo,
      attribution,
      couponToken,
    };
    return ApiService.action('users_register', {
      data,
      method: 'post',
      withCredentials: true,
    });
  };

  const service = {
    init: (ApiServiceInstance: IApiService) => {
      ApiService = ApiServiceInstance;
    },
    validate,
    getRoles,
    login,
    signup,
  };
  return service;
}

const AuthService = createAuthService();
export default AuthService;
